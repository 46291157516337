import produce from 'immer'
import * as constants from '../../constants'
import * as viewConstants from './constants'
import { getDatesFromRange } from '../../utils'

const defaultGroup = 'month'
const defaultRange = ''
const defaultDates = getDatesFromRange( defaultRange )

const initialState = {
  loaded: false,
  invalid: true,
  filter: {
    local: false,
    date: {
      range: defaultRange,
      start: defaultDates.start,
      end: defaultDates.end,
      group: defaultGroup,
    },
    search: {
      //Hidden: "-1",
    },
    filter: {
    },
    sort : {
    },
  },
  records: [],
  record: null,
  sales: {
    dateGroup: defaultGroup,
    dateRange: defaultRange,
    dateStart: defaultDates.start,
    dateEnd: defaultDates.end,
    graphData: [],
  }
};

export default ( state = initialState, action ) => 
{
  switch( action.type ) {
    case viewConstants.RECORDS_LOAD+constants.SUCCESS_SUFFIX :
      return produce( state, newState=>{
        newState.loaded = true
        newState.invalid = false
        newState.record = null
        if ( !newState.filter.local )
          newState.records = action.payload.records
      })
    case viewConstants.RECORDS_SORT :
      return produce( state, newState=>{
        newState.filter.sort = {
          col: action.payload.field,
          asc: (action.payload.field!==newState.filter.sort.col)?false:!newState.filter.sort.asc,
        }
        if ( !newState.filter.local )
          newState.invalid = true
      })
    case viewConstants.RECORDS_SEARCH :
      return produce( state, newState=>{
        newState.filter.search[action.payload.field] = action.payload.text
        if ( !newState.filter.local )
          newState.invalid = true
      })
    case viewConstants.RECORDS_FILTER :
      return produce( state, newState=>{
        newState.filter.filter[action.payload.field] = action.payload.value
        if ( !newState.filter.local )
          newState.invalid = true
      })
    case viewConstants.RECORDS_DATE :
      return produce( state, newState=>{
        newState.filter.date = { ...newState.filter.date, ...action.payload.date }
        if ( !newState.filter.local )
          newState.invalid = true
      })
    case viewConstants.RECORDS_RESET :
      return produce( state, newState=>{
        newState.filter = initialState.filter
        if ( !newState.filter.local )
          newState.invalid = true
      })
    case viewConstants.DATE_CHANGE :
      return produce( state, newState=>{
        newState.filter = initialState.filter
        if ( !newState.filter.local )
          newState.invalid = true
      })
    default :

  }
  return state;
}
