import { call, put, select, takeLatest, all } from 'redux-saga/effects'

import * as constants from '../../constants'
import * as entityConstants from '../../entities/contact/constants'
import * as contactApi from 'entities/contact/api/'
import { fetchDependentData, fetchFilteredRecords } from 'model/sagas'

const getContactState = (state) => state.views.contacts.contact

function *fetchBaseData() {
 const viewState = yield select(getContactState)
  yield call( fetchDependentData, viewState, [ 'base' ] )
}

function* fetchRecords( action ) {
  try {
    //if ( debounce )
      //yield delay( debounce )
  	const viewState = yield select(getContactState)
    const [ , ] = yield all([
      call( fetchBaseData ),
    ])
    yield call( fetchFilteredRecords, viewState, contactApi.getRecords, entityConstants.RECORDS_LOAD )
  } catch (e) {
    console.error( e )
  }
}

function* fetchRecord( action ) {
  try {
    yield call( contactApi.getRecord, action.payload.contact_id, action.type )
  } catch (e) {
    console.error( e )
  }
}

function* updateRecord( action ) {
  try {
    yield call( contactApi.updateRecord, action.payload )
    //const user = yield call(Api.fetchUser, action.payload.userId);
    //yield put({type: "USER_FETCH_SUCCEEDED", user: user});
  } catch (e) {
    console.error( e )
    //yield put({type: "USER_FETCH_FAILED", message: e.message});
  }
}

function* deleteRecord( action ) {
  try {
    yield call( contactApi.deleteRecord, action.payload )
    //const user = yield call(Api.fetchUser, action.payload.userId);
    //yield put({type: "USER_FETCH_SUCCEEDED", user: user});
  } catch (e) {
    console.error( e )
    //yield put({type: "USER_FETCH_FAILED", message: e.message});
  }
}

function *add( action ) {
  const record = action.payload;
  yield put({type: entityConstants.EDIT_MODAL_OPEN, payload: record});
}

function *edit( action ) {
  const record = action.payload;
  yield put({type: entityConstants.RECORD_LOAD, payload: record});
}

function *recordLoaded( action ) {
  const record = action.payload;
  yield put({type: entityConstants.EDIT_MODAL_OPEN, payload: record});
}

function *recordUpdated( action ) {
  yield put({type: entityConstants.EDIT_MODAL_CLOSE});
  yield call( fetchRecords );
}

function *deletX( action ) {
  const record = action.payload;
  yield put({type: entityConstants.DELETE_MODAL_OPEN, payload: record});
}

function *recordDeleted( action ) {
  yield put({type: entityConstants.DELETE_MODAL_CLOSE});
  yield call( fetchRecords );
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* mySaga() {
  yield takeLatest( entityConstants.RECORDS_LOAD, fetchRecords );
  yield takeLatest( entityConstants.RECORDS_PAGE, fetchRecords );
  yield takeLatest( entityConstants.RECORDS_SORT, fetchRecords );
  yield takeLatest( entityConstants.RECORDS_SEARCH, fetchRecords );
  yield takeLatest( entityConstants.RECORDS_DATE, fetchRecords );
  yield takeLatest( entityConstants.RECORD_LOAD, fetchRecord );
  yield takeLatest( entityConstants.RECORD_LOAD+constants.SUCCESS_SUFFIX, recordLoaded );
  yield takeLatest( entityConstants.RECORD_UPDATE, updateRecord );
  yield takeLatest( entityConstants.RECORD_UPDATE+constants.SUCCESS_SUFFIX, recordUpdated );
  yield takeLatest( entityConstants.RECORD_DELETE, deleteRecord );
  yield takeLatest( entityConstants.RECORD_DELETE+constants.SUCCESS_SUFFIX, recordDeleted );
  yield takeLatest( entityConstants.ADD, add );
  yield takeLatest( entityConstants.EDIT, edit );
  yield takeLatest( entityConstants.DELETE, deletX );

  // Wake up when user starts timer.
  //while( yield take(entityConstants.RECORDS_LOAD) ) {
    //while( true ) {
      // This side effect is not run yet, so it can be treated
      // as data, making it easier to test if needed.
      //yield call( delay, config.UPDATE_INTERVAL )
      //yield put( appActions.lastUpdated() )
    //}
  //}
}

export default mySaga;
