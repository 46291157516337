import produce from 'immer'

import * as constants from '../../constants'
import * as bookingConstants from 'entities/booking/constants'
import entityReducer from 'entities/reducer'
import createState from '../../entities/state'
import { getDatesFromRange } from 'utils'

const range = '';
const dates = getDatesFromRange( range );

const initialState = {
  booking: createState({
    filter: { 
      local: false, 
      date: { 
        range: range,
        start: dates.start,
        end: dates.end, 
      },
      filter: {
        booking_deleted: null
      },
      sort : { 
        col: "booking_shoot_date",
        asc: false,
      },
    },
    totals: {
      paid: 0,
      unpaid: 0,
      overdue: 0,
    },
  }),
};

const bookingReducer = entityReducer( bookingConstants.ENTITY, initialState );

export default ( state = initialState, action ) => {
  return produce( state, newState => {
    switch( action.type ) {
    	case bookingConstants.TOTALS_LOAD+constants.SUCCESS_SUFFIX :
        newState.booking.totals = action.payload
        break
			/*case constants.BOOKING_CALENDAR_START :
				return produce( state, newState => {
					newState.calendar = {
						from: action.payload,
						to: null,
						enteredTo: null,
					}
				})
			case constants.BOOKING_CALENDAR_HOVER :
				return produce( state, newState => {
					newState.calendar.enteredTo = action.payload;
				})
			case constants.BOOKING_CALENDAR_END :
				return produce( state, newState => {
					newState.calendar.to = newState.calendar.endteredTo = action.payload;
				})
			case constants.BOOKING_CALENDAR_RESET :
				return produce( state, newState => {
					newState.calendar = {
						from: null,
						to: null,
						enteredTo: null,
					}
				})*/
      default :
    }
    //console.log( "S1", state )
    newState.booking = bookingReducer( newState.booking, action )
  })
}
