import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { object, number, string } from 'yup';
import { reduxForm, isValid, submit } from 'redux-form'

import { EditModal } from '../../components/modals'
import { FormInput, validateSchema } from '../../components/forms/redux-form'
//import { getEditedContact } from './selectors'

const FORM_ID = 'service';

const schema = object().shape({
  service_tag:    string().required().nullable().label("Tag"),
  service_name:   string().required().nullable().label("Name"),
  service_price:  number().nullable().label("Price"),
});

const ServiceFormBase = props => {

  const { handleSubmit, onSubmit } = props
  console.log( "SFB", props )
  return (
    <Form success={props.valid} warning={props.warning} error={props.invalid} onSubmit={()=>handleSubmit(onSubmit)}>
      <Form.Group>
        <FormInput
          name="service_tag"
          required
          prompt="Tag"
          width={3}
        />
        <FormInput
          name="service_name"
          required
          prompt="Name"
          width={9}
        />
        <FormInput
          name="service_price"
          type="number"
          prompt="DefaultPrice"
          width={4}
        />
      </Form.Group>
    </Form>
  )
}

const ServiceForm = reduxForm({
  form: FORM_ID,
  validate: validateSchema(schema),
})(ServiceFormBase)

export { ServiceForm }

const EditServiceModalBase = props => {
  const editing = props.record && props.record.service_id;
  const title = (editing?"Edit":"Create")+" Service"+(editing?(" "+props.record.service_id):"");
  if ( !props.record )
    return null
  return(
    <EditModal show={props.show} toggle={props.toggle} title={title} canSubmit={()=>props.valid} onSubmit={props.doSubmit}>
      <ServiceForm initialValues={props.record} onSubmit={props.onChange}/>
    </EditModal>
  )
}

export default connect(store=>({
  valid: isValid(FORM_ID)(store),
}),{
  doSubmit: () => submit(FORM_ID)
})(EditServiceModalBase)
