import React from 'react'
import { connect } from 'react-redux'
import { Message, Button, Icon } from 'semantic-ui-react';
import moment from 'moment'

import { ConfirmModal } from '../components/modals'
import dialogActions from './actions'
import ConfigModal from '../config/forms'
import EditBookingModal from 'entities/booking/forms'
import bookingActions from 'entities/booking/actions'
import EditContactModal from 'entities/contact/forms'
import contactActions from 'entities/contact/actions'
import EditServiceModal from 'entities/service/forms'
import serviceActions from 'entities/service/actions'

const ModalsBase = ( props ) => {
  return (
    <div>
      <ConfigModal
        show={props.configModal.open}
        toggle={()=>props.configModalClose()}
        onChange={props.configUpdate}
      />
      <EditBookingModal
        record={props.editBookingModal.booking}
        show={props.editBookingModal.open}
        toggle={()=>props.bookingEditModalClose()}
        onChange={props.bookingRecordUpdate}
      />
      <ConfirmModal
        show={props.deleteBookingModal.open}
        title="Confirm Delete Booking"
        message="Are you sure you want to delete this booking?"
        onSubmit={()=>props.bookingRecordDelete(props.deleteBookingModal.booking)}
        toggle={()=>props.bookingDeleteModalClose()}
      />
      <EditContactModal
        record={props.editContactModal.contact}
        show={props.editContactModal.open}
        toggle={()=>props.contactEditModalClose()}
        onChange={props.contactRecordUpdate}
      />
      <ConfirmModal
        show={props.deleteContactModal.open}
        title="Confirm Delete Contact"
        message="Are you sure you want to delete this contact?"
        onSubmit={()=>props.contactRecordDelete(props.deleteContactModal.contact)}
        toggle={()=>props.contactDeleteModalClose()}
      />
      <EditServiceModal
        record={props.editServiceModal.service}
        show={props.editServiceModal.open}
        toggle={()=>props.serviceEditModalClose()}
        onChange={props.serviceRecordUpdate}
      />
      <ConfirmModal
        show={props.deleteServiceModal.open}
        title="Confirm Delete Service"
        message="Are you sure you want to delete this service?"
        onSubmit={()=>props.serviceRecordDelete(props.deleteServiceModal.service)}
        toggle={()=>props.serviceDeleteModalClose()}
      />
    </div>
  )
}

export const Modals = connect(store=>(store.dialogs),{
  ...dialogActions,
  ...bookingActions,
  ...contactActions,
  ...serviceActions,
})(ModalsBase);

const NotificationsBase = ( props ) => {
  if ( props.updateNotification.open )
  {
    const updateTime = moment().format("HH:mm:ss")
    return (
      <Message success={props.updateNotification.type==='app'} warning={props.updateNotification.type==='data'} icon onDismiss={()=>props.updateNotificationClose()}>
        <Icon name="exclamation triangle"/>
        <Message.Content>
          <Message.Header>Update</Message.Header>
          {updateTime}: {props.updateNotification.message} <Button type="button" compact size="mini" onClick={()=>props.updateRefresh(props.updateNotification.type)} icon="refresh"/>
        </Message.Content>
      </Message>
    )
  }
  if ( props.errorNotification.open )
  {
    const updateTime = moment().format("HH:mm:ss")
    return (
      <Message error icon onDismiss={()=>props.errorNotificationClose()}>
        <Icon name="exclamation triangle"/>
        <Message.Content>
          <Message.Header>Error</Message.Header>
          {updateTime}: API Error - {props.errorNotification.message}
        </Message.Content>
      </Message>
    )
  }
  return null
}

export const Notifications = connect(store=>(store.dialogs),dialogActions)(NotificationsBase);
