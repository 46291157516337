import { createActions } from 'redux-actions'
import * as constants from '../constants';
import * as bookingConstants from 'entities/booking/constants';
import * as contactConstants from 'entities/contact/constants';
import * as serviceConstants from 'entities/service/constants';

const actions = createActions({
  [constants.CONFIG_MODAL_OPEN]: null,
  [constants.CONFIG_MODAL_CLOSE]: null,
  [bookingConstants.EDIT_MODAL_OPEN]: booking => ({ booking }),
  [bookingConstants.EDIT_MODAL_CLOSE]: null,
  [bookingConstants.DELETE_MODAL_OPEN]: booking => ({ booking }),
  [bookingConstants.DELETE_MODAL_CLOSE]: null,
  [contactConstants.EDIT_MODAL_OPEN]: contact => ({ contact }),
  [contactConstants.EDIT_MODAL_CLOSE]: null,
  [contactConstants.DELETE_MODAL_OPEN]: contact => ({ contact }),
  [contactConstants.DELETE_MODAL_CLOSE]: null,
  [serviceConstants.EDIT_MODAL_OPEN]: service => ({ service }),
  [serviceConstants.EDIT_MODAL_CLOSE]: null,
  [serviceConstants.DELETE_MODAL_OPEN]: service => ({ service }),
  [serviceConstants.DELETE_MODAL_CLOSE]: null,
  [constants.UPDATE_NOTIFICATION_OPEN]: null,
  [constants.UPDATE_NOTIFICATION_CLOSE]: null,
  [constants.UPDATE_REFRESH]: type => type,
  [constants.ERROR_NOTIFICATION_OPEN]: message => message,
  [constants.ERROR_NOTIFICATION_CLOSE]: null,
});

export default actions
