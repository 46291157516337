import produce from 'immer'

import * as serviceConstants from 'entities/service/constants'
import entityReducer from 'entities/reducer'
import createState from '../../entities/state'
import { getDatesFromRange } from 'utils'

const range = '';
const dates = getDatesFromRange( range );

const initialState = {
  service: createState({
    filter: { 
      local: false, 
      date: { 
        range: range,
        start: dates.start,
        end: dates.end, 
      },
      filter: {
        service_deleted: null
      },
      sort : { 
        col: "service_name",
        asc: true,
      },
    },
  }),
};

const serviceReducer = entityReducer( serviceConstants.ENTITY, initialState );

export default ( state = initialState, action ) => {
  return produce( state, newState => {
    newState.service = serviceReducer( newState.service, action )
  })
}
