import * as constants from './constants'
import api from '../../api'

export const getReportAttrs = () => {
  let request = '/report-attrs';
  return api.makeBasicRequest( request );
}

export const getReportData = ( query, report, action ) => {
  let request = '/reports/'+report;
  if ( query )
    request += '?'+query.join('&');
  return api.makeRequest( request, constants.RECORDS_LOAD );
}
