import { createSelector } from 'reselect'

export const getAccessState = state => state.access

export const getProgress = createSelector(
  [ getAccessState ],
  accessState => accessState.progress
)

export const getLoginMessage = createSelector(
  [ getAccessState ],
  accessState => accessState.loginMessage
)

export const getRegisterMessage = createSelector(
  [ getAccessState ],
  accessState => accessState.registerMessage
)

export const getConfirmMessage = createSelector(
  [ getAccessState ],
  accessState => accessState.confirmMessage
)

export const getAccessUser = createSelector(
  [ getAccessState ],
  accessState => accessState.user
)
