import produce from 'immer'
import * as constants from '../constants'
import * as bookingConstants from 'entities/booking/constants';
import * as contactConstants from 'entities/contact/constants';
import * as serviceConstants from 'entities/service/constants';

const initialState = {
  configModal: {
    open: false,
  },
  editBookingModal: {
    open: false,
    booking: null,
  },
  deleteBookingModal: {
    open: false,
    booking: null,
  },
  editContactModal: {
    open: false,
    contact: null,
  },
  deleteContactModal: {
    open: false,
    contact: null,
  },
  editServiceModal: {
    open: false,
    service: null,
  },
  deleteServiceModal: {
    open: false,
    service: null,
  },
  updateNotification: {
    open: false,
    type: null,
    message: null,
  },
  errorNotification: {
    open: false,
    message: null,
  },
};

const dialogsReducer = function( state = initialState, action )
{
  switch( action.type )
  {
    case constants.CONFIG_MODAL_OPEN :
      return produce( state, newState=>{
        newState.configModal = {
          open: true,
        }
      })
    case constants.CONFIG_MODAL_CLOSE :
      return produce( state, newState=>{
        newState.configModal = {
          open: false,
        }
      })

    case bookingConstants.EDIT_MODAL_OPEN :
      return produce( state, newState=>{
        newState.editBookingModal = {
          open: true,
          booking: action.payload,
        }
      })
    case bookingConstants.EDIT_MODAL_CLOSE :
      return produce( state, newState=>{
        newState.editBookingModal = {
          open: false,
          booking: null,
        }
      })
    case bookingConstants.DELETE_MODAL_OPEN :
      return produce( state, newState=>{
        newState.deleteBookingModal = {
          open: true,
          booking: action.payload,
        }
      })
    case bookingConstants.DELETE_MODAL_CLOSE :
      return produce( state, newState=>{
        newState.deleteBookingModal = {
          open: false,
          booking: null,
        }
      })

    case contactConstants.EDIT_MODAL_OPEN :
      return produce( state, newState=>{
        newState.editContactModal = {
          open: true,
          contact: action.payload,
        }
      })
    case contactConstants.EDIT_MODAL_CLOSE :
      return produce( state, newState=>{
        newState.editContactModal = {
          open: false,
          contact: null,
        }
      })
    case contactConstants.DELETE_MODAL_OPEN :
      return produce( state, newState=>{
        newState.deleteContactModal = {
          open: true,
          contact: action.payload,
        }
      })
    case contactConstants.DELETE_MODAL_CLOSE :
      return produce( state, newState=>{
        newState.deleteContactModal = {
          open: false,
          contact: null,
        }
      })

    case serviceConstants.EDIT_MODAL_OPEN :
      return produce( state, newState=>{
        newState.editServiceModal = {
          open: true,
          service: action.payload,
        }
      })
    case serviceConstants.EDIT_MODAL_CLOSE :
      return produce( state, newState=>{
        newState.editServiceModal = {
          open: false,
          service: null,
        }
      })
    case serviceConstants.DELETE_MODAL_OPEN :
      return produce( state, newState=>{
        newState.deleteServiceModal = {
          open: true,
          service: action.payload,
        }
      })
    case serviceConstants.DELETE_MODAL_CLOSE :
      return produce( state, newState=>{
        newState.deleteServiceModal = {
          open: false,
          service: null,
        }
      })

    case constants.UPDATE_NOTIFICATION_OPEN :
      return produce( state, newState=>{
        newState.updateNotification = {
          open: true,
          type: action.payload.type,
          message: action.payload.message,
        }
      })
    case constants.UPDATE_NOTIFICATION_CLOSE :
    case constants.UPDATE_REFRESH :
      return produce( state, newState=>{
        newState.updateNotification = {
          open: false,
          type: null,
          message: null,
        }
      })

    case constants.ERROR_NOTIFICATION_OPEN :
      return produce( state, newState=>{
        newState.errorNotification = {
          open: true,
          message: action.payload,
        }
      })
    case constants.ERROR_NOTIFICATION_CLOSE :
      return produce( state, newState=>{
        newState.errorNotification = {
          open: false,
          message: null,
        }
      })

    default:
  }
  return state
}

export default dialogsReducer;
