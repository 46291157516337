import { call, select, takeLatest, delay } from 'redux-saga/effects'

import * as viewConstants from './constants'
import * as viewApi from './api'
import { fetchDependentData, fetchFilteredRecords } from 'model/sagas'

const getViewState = (state) => state.views.reports

function *fetchBaseData() {
  const viewState = yield select(getViewState)
  yield call( fetchDependentData, viewState, [ 'itemAttrs', 'invoiceAttrs' ] )
}

function* fetchReportRecords( debounce, action ) {
  try {
    if ( debounce )
      yield delay( debounce )
    const viewState = yield select(getViewState)
    yield call( fetchBaseData )
    yield call( fetchFilteredRecords, viewState, viewApi.getReportData, action.payload.report, viewConstants.RECORDS_LOAD )
  } catch (e) {
    console.error( e )
  }
}

export default function*() {
  yield takeLatest( viewConstants.RECORDS_LOAD, fetchReportRecords, 0 );
  yield takeLatest( viewConstants.RECORDS_SORT, fetchReportRecords, 0 );
  yield takeLatest( viewConstants.RECORDS_SEARCH, fetchReportRecords, 250 );
  yield takeLatest( viewConstants.RECORDS_FILTER, fetchReportRecords, 0 );
  yield takeLatest( viewConstants.RECORDS_DATE, fetchReportRecords, 0 );
  yield takeLatest( viewConstants.RECORDS_RESET, fetchReportRecords, 0 );
}
