import produce from 'immer'

import * as contactConstants from 'entities/contact/constants'
import entityReducer from 'entities/reducer'
import createState from '../../entities/state'

const initialState = {
  contact: createState({
    filter: { 
      local: false, 
      filter: {
        contact_deleted: null
      },
      sort : { 
        col: "contact_name",
        asc: true,
      },
    },
  }),
};

const contactReducer = entityReducer( contactConstants.ENTITY, initialState );

export default ( state = initialState, action ) => {
  return produce( state, newState => {
    switch( action.type ) {
    	//case contactConstants.RECORDS_LOAD+constants.SUCCESS_SUFFIX :
      	//return produce( state, newState => {
        	//newState.contact.invalid = false
      	//})
        //break
      //default :
    }
    newState.contact = contactReducer( newState.contact, action )
  })
}
