import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Table, Form } from 'semantic-ui-react';
import { object, number } from 'yup';
import { formValueSelector, reduxForm, isValid, submit } from 'redux-form'

import { EditModal } from 'components/modals'
import { OkButton, UneditButton, AddButton, EditButton, DeleteButton } from 'components/buttons'
import { round, Money } from 'components/formats'
import { FormTextArea, FormDropDown, FormCheckBox, FormButton, FormDateTimePicker, FormInput, validateSchema } from 'components/forms/redux-form'
import { getServices, getInvoiceTerms } from '../../model/selectors'
//import { getEditedContact } from './selectors'
import contactActions from '../contact/actions'
import * as api from 'entities/contact/api/'

const BOOKING_FORM_ID = 'booking';

const schema = object().shape({
  client_id:          number().required().positive().integer().label("Client"),
  //booking_shoot_date: date().required().label("Date"),
  //booking_terms:      string().required().nullable().label("Terms"),
});

class BookingFormBase extends Component {
  constructor( props ) {
    super( props )
    const serviceOptions = props.services.map( service => ({ text: service.service_name, value: service.service_id }) );
    this.state = {
      fetchingClients: false,
      clients: [],
      fetchingAgents: false,
      agents: [],
      fetchingInvoicees: false,
      invoicees: [],
      termOptions: [
        {text:'Chargeable',value:'chargeable'},
        {text:'Cost In Kind',value:'costinkind'}
      ],
      serviceOptions,
      editing: false,
    }
  }
  componentDidMount() {
    if ( !this.state.clients.length && this.props.initialValues.client_id )
      this.setState({ clients: [{ text: this.props.initialValues.client_label, value: this.props.initialValues.client_id }] });
    else
      this.searchClients();
    if ( !this.state.agents.length && this.props.initialValues.agent_id )
      this.setState({ agents: [{ text: this.props.initialValues.agent_label, value: this.props.initialValues.agent_id }] });
    else
      this.searchAgents();
    if ( !this.state.invoicees.length && this.props.initialValues.invoicee_id )
      this.setState({ invoicees: [{ text: this.props.initialValues.invoicee_label, value: this.props.initialValues.invoicee_id }] });
    else
      this.searchInvoicees();
  }
  componentDidUpdate( prevProps ) {
    if ( this.props.services.length !== prevProps.services.length )
    {
      const serviceOptions = this.props.services.map( service => ({ text: service.service_name, value: service.service_id }) );
      this.setState({ serviceOptions });
    }
    if ( this.props.client )
    {
      if ( !prevProps.client )
      {
        // A new client has been added or edited
        if ( this.props.client.contact_id !== this.props.values.client_id )
          this.props.change( "client_id", this.props.client.contact_id );
      }
      else if ( this.props.client.contact_label !== prevProps.client.contact_label )
      {
        this.searchClients();
      }
    }
    if ( this.props.agent )
    {
      if ( !prevProps.agent )
      {
        // A new agent has been added or edited
        if ( this.props.agent.contact_id !== this.props.values.agent_id )
          this.props.change( "agent_id", this.props.agent.contact_id );
      }
      else if ( this.props.agent.contact_label !== prevProps.agent.contact_label )
      {
        this.searchClients();
      }
    }
    if ( this.props.invoicee )
    {
      if ( !prevProps.invoicee )
      {
        // A new invoicee has been added or edited
        if ( this.props.invoicee.contact_id !== this.props.values.invoicee_id )
          this.props.change( "invoicee_id", this.props.invoicee.contact_id );
      }
      else if ( this.props.invoicee.contact_label !== prevProps.invoicee.contact_label )
      {
        this.searchClients();
      }
    }
  }
  searchClients = ( searchText=null ) => {
    this.setState({ fetchingClients: true }, () =>
      api.searchRecords( searchText, 100 )
      .then( result => {
        const clients = result.data.records.map( record => ({ text: record.contact_label, value: record.contact_id }) );
        this.setState({ fetchingClients: false, clients });
      })
    );
  }
  updateClient = ( e, data ) => {
    if ( !data.value )
      this.searchClients();
  }
  updateClients = ( e, data ) => {
    this.searchClients( data.searchQuery );
  }
  searchAgents = ( searchText=null ) => {
    this.setState({ fetchingAgents: true }, () =>
      api.searchRecords( searchText, 100 )
      .then( result => {
        const agents = result.data.records.map( record => ({ text: record.contact_label, value: record.contact_id }) );
        this.setState({ fetchingAgents: false, agents });
      })
    );
  }
  updateAgent = ( e, data ) => {
    if ( !data.value )
      this.searchAgents();
  }
  updateAgents = ( e, data ) => {
    this.searchAgents( data.searchQuery );
  }
  searchInvoicees = ( searchText=null ) => {
    this.setState({ fetchingInvoicees: true }, () =>
      api.searchRecords( searchText, 100 )
      .then( result => {
        const invoicees = result.data.records.map( record => ({ text: record.contact_label, value: record.contact_id }) );
        this.setState({ fetchingInvoicees: false, invoicees });
      })
    );
  }
  updateInvoicee = ( e, data ) => {
    if ( !data.value )
      this.searchInvoicees();
  }
  updateInvoicees = ( e, data ) => {
    this.searchInvoicees( data.searchQuery );
  }
  updateTotal = () => {
    const total = round(this.props.values.services.reduce( ( value, service ) => parseFloat(service.booking_service_price) ? value + parseFloat(service.booking_service_price) : value, 0.0 ))
    this.props.change( "booking_total", total );
  }
  serviceAdd = serviceSeq => {
    this.setState({ editing: serviceSeq })
    this.props.array.push( 'services', {
      booking_service_seq: serviceSeq,
      service_id: null,
      booking_id: this.props.initialValues.booking_id,
    })
  }
  serviceEdit = index => {
    const editService = Object.assign( {}, this.props.values.services[index] )
    this.setState({ editing: editService.booking_service_seq, editService })
  }
  serviceSelect = ( index, data ) => {
    const service = this.props.services.find( svc => svc.service_id === data.value )
    const booking_service = this.props.values.services[index];
    if ( !booking_service.booking_service_price )
      this.props.change( `services.${index}.booking_service_price`, service.service_price )
  }
  serviceCancel = index => {
    if ( this.state.editService )
      this.props.array.splice( 'services', index, index, this.state.editService )
    else
      this.props.array.remove( 'services', index )
    this.setState({ editing: null, editService: null })
  }
  serviceSave = index => {
    this.setState({ editing: null, editService: null })
    this.updateTotal()
  }
  serviceDelete = index => {
    this.props.array.remove( 'services', index )
    this.updateTotal()
  }
  render() {
    const { handleSubmit, onSubmit } = this.props
    const nextServiceSeq = this.props.values.services.reduce( ( value, service ) => parseInt(service.booking_service_seq,10) > value ? parseInt(service.booking_service_seq,10) : value, 0 )+1
    const invoiceTerms = this.props.invoiceTerms.map( term => ({ text: term, value: term }) )
    console.log( "BFB", nextServiceSeq, this.props )
    return (
      <Form success={this.props.valid} warning={this.props.warning} error={this.props.invalid} onSubmit={()=>handleSubmit(onSubmit)}>
        <Form.Group>
          <FormDropDown
            name="client_id"
            prompt="Client"
            placeholder="Choose Client"
            required
            search
            selection
            clearable
            options={this.state.clients}
            onChange={this.updateClient}
            onSearchChange={this.updateClients}
            disabled={this.state.fetchingClients}
            loading={this.state.fetchingClients}
            width={4}
          />
          {this.props.values.client_id &&
          <FormButton
            positive
            title="Edit Client"
            icon="edit"
            onClick={()=>this.props.contactEdit({contact_id: this.props.values.client_id})}
          />
          }
          {!this.props.values.client_id &&
          <FormButton
            positive
            title="New Client"
            icon="plus"
            onClick={()=>this.props.contactAdd({})}
          />
          }
          <FormDropDown
            name="agent_id"
            prompt="Agent"
            placeholder="Choose Agent"
            search
            selection
            clearable
            options={this.state.agents}
            onChange={this.updateAgent}
            onSearchChange={this.updateAgents}
            disabled={this.state.fetchingAgents}
            loading={this.state.fetchingAgents}
            width={4}
          />
          {this.props.values.agent_id &&
          <FormButton
            positive
            title="Edit Agent"
            icon="edit"
            onClick={()=>this.props.contactEdit({contact_id: this.props.values.agent_id})}
          />
          }
          {!this.props.values.agent_id &&
          <FormButton
            positive
            title="New Agent"
            icon="plus"
            onClick={()=>this.props.contactAdd({})}
          />
          }
          <FormDropDown
            name="invoicee_id"
            prompt="Invoice To"
            placeholder="Choose Invoice To"
            search
            selection
            clearable
            options={this.state.invoicees}
            onChange={this.updateInvoicee}
            onSearchChange={this.updateInvoicees}
            disabled={this.state.fetchingInvoicees}
            loading={this.state.fetchingInvoicees}
            width={4}
          />
          {this.props.values.invoicee_id &&
          <FormButton
            positive
            title="Edit Invoicee"
            icon="edit"
            onClick={()=>this.props.contactEdit({contact_id: this.props.values.invoicee_id})}
          />
          }
          {!this.props.values.invoicee_id &&
          <FormButton
            positive
            title="New Invoicee"
            icon="plus"
            onClick={()=>this.props.contactAdd({})}
          />
          }
        </Form.Group>
        <Form.Group>
          <FormInput
            name="booking_title"
            prompt="Booking/Event Title"
            width={8}
          />
          <FormInput
            name="booking_reference"
            prompt="Reference"
            width={4}
          />
          <FormDropDown
            name="booking_terms"
            prompt="Terms"
            placeholder="Terms"
            selection
            clearable
            options={this.state.termOptions}
            fluid
            width={4}
          />
        </Form.Group>
        <Form.Group>
          <FormDateTimePicker
            name="booking_shoot_date"
            prompt="Shoot Date"
            required
            displayFormat="Do MMM YYYY"
            storeFormat="YYYY-MM-DD"
            time={false}
            width={4}
          />
          <FormInput
            name="booking_location"
            prompt="Location"
            width={6}
          />
          <FormCheckBox
            name="booking_travel_inc"
            prompt="Travel Inc."
            trueValue="1"
            falseValue="0"
            width={2}
            toggle
          />
          <FormDateTimePicker
            name="booking_ss_sent"
            prompt="SS Sent"
            displayFormat="Do MMM YYYY"
            storeFormat="YYYY-MM-DD"
            time={false}
            width={4}
          />
        </Form.Group>
        <Form.Group>
          <FormDateTimePicker
            name="booking_invoice_date"
            prompt="Invoice Date"
            required
            displayFormat="Do MMM YYYY"
            storeFormat="YYYY-MM-DD"
            time={false}
            width={3}
          />
          <FormDropDown
            name="booking_invoice_terms"
            prompt="Invoice Terms"
            placeholder="Invoice Terms"
            selection
            clearable
            options={invoiceTerms}
            fluid
            width={3}
          />
          <FormInput
            name="booking_deposit"
            type="number"
            prompt="Deposit"
            required
            width={2}
          />
          <FormDateTimePicker
            name="booking_deposit_paid"
            prompt="Deposit Paid"
            displayFormat="Do MMM YYYY"
            storeFormat="YYYY-MM-DD"
            time={false}
            width={3}
            disabled={!this.props.values.booking_deposit}
          />
          <FormInput
            name="booking_total"
            type="number"
            prompt="Total"
            required
            width={2}
          />
          <FormDateTimePicker
            name="booking_total_paid"
            prompt="Total Paid"
            displayFormat="Do MMM YYYY"
            storeFormat="YYYY-MM-DD"
            time={false}
            width={3}
            disabled={!this.props.values.booking_total}
          />
        </Form.Group>
        <Form.Group>
          <FormInput
            name="booking_cik_details"
            prompt="Cost In Kind Details"
            width={6}
          />
          <FormInput
            name="booking_provide_dog"
            prompt="Provide Dog(s)"
            width={5}
          />
          <FormInput
            name="booking_provide_model"
            prompt="Provide Model(s)"
            width={5}
          />
        </Form.Group>
        <Table id="booking_services" size="small" compact="very" selectable striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content="#"/>
              <Table.HeaderCell content="Service"/>
              <Table.HeaderCell className="amount" content="Qty."/>
              <Table.HeaderCell className="money" content="Price"/>
              <Table.HeaderCell content="Notes"/>
              <Table.HeaderCell><AddButton onClick={()=>this.serviceAdd(nextServiceSeq)} disabled={!!this.state.editing}/></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
          {this.props.values.services.map( (service,index) =>
            <Table.Row key={service.booking_service_seq}>
              {this.state.editing!==service.booking_service_seq &&
              <Fragment>
                <Table.Cell>{service.booking_service_seq}</Table.Cell>
                <Table.Cell>{this.props.services.find( svc => svc.service_id === service.service_id ).service_name}</Table.Cell>
                <Table.Cell className="amount">{service.booking_service_quantity}</Table.Cell>
                <Table.Cell className="money"><Money value={service.booking_service_price}/></Table.Cell>
                <Table.Cell>{service.booking_service_notes}</Table.Cell>
                <Table.Cell>
                  <EditButton onClick={()=>this.serviceEdit(index)} disabled={!!this.state.editing}/>
                  <DeleteButton onClick={()=>this.serviceDelete(index)} disabled={!!this.state.editing}/>
                </Table.Cell>
              </Fragment>
              }
              {this.state.editing===service.booking_service_seq &&
              <Fragment>
                <Table.Cell>{service.booking_service_seq}</Table.Cell>
                <Table.Cell>
                  <FormDropDown
                    name={`services.${index}.service_id`}
                    placeholder="Service"
                    selection
                    options={this.state.serviceOptions}
                    onChange={(e,data)=>this.serviceSelect(index,data)}
                  />
                </Table.Cell>
                <Table.Cell className="amount">
                  <FormInput
                    name={`services.${index}.booking_service_quantity`}
                  />
                </Table.Cell>
                <Table.Cell className="money">
                  <FormInput
                    name={`services.${index}.booking_service_price`}
                    type="number"
                  />
                </Table.Cell>
                <Table.Cell>
                  <FormInput
                    name={`services.${index}.booking_service_notes`}
                  />
                </Table.Cell>
                <Table.Cell>
                  <UneditButton onClick={()=>this.serviceCancel(index)}/>
                  <OkButton onClick={()=>this.serviceSave(index)} disabled={!this.props.values.services[index].service_id||!this.props.values.services[index].booking_service_price}/>
                </Table.Cell>
              </Fragment>
              }
            </Table.Row>
          )}
          </Table.Body>
        </Table>       
        <FormTextArea
          name="booking_invoice_notes"
          prompt="Invoice Notes"
          placeholder="Notes"
          rows={4}
        />
        <FormTextArea
          name="booking_notes"
          prompt="Private Notes"
          placeholder="Private Notes"
          rows={4}
        />
      </Form>
    )
  }
}

const valueSelector = formValueSelector(BOOKING_FORM_ID);

const BookingForm = reduxForm({
  form: BOOKING_FORM_ID,
  validate: validateSchema(schema),
})(connect(store=>({
  values: valueSelector(store,
    'client_id',
    'booking_confirmed',
    'booking_deposit',
    'booking_total',
    'services',
  ),
  services: getServices(store),
  invoiceTerms: getInvoiceTerms(store),
  //contact: getEditedContact(store),
}),{
  ...contactActions,
})(BookingFormBase))

export { BookingForm }

const EditBookingModalBase = props => {
  const editing = props.record && props.record.booking_id;
  const title = (editing?"Edit":"Create")+" Booking"+(editing?(" "+props.record.booking_id):"");
  if ( !props.record )
    return null
  return(
    <EditModal show={props.show} toggle={props.toggle} title={title} size="large" canSubmit={()=>props.valid} onSubmit={props.doSubmit}>
      <BookingForm initialValues={props.record} onSubmit={props.onChange}/>
    </EditModal>
  )
}

export default connect(store=>({
  valid: isValid(BOOKING_FORM_ID)(store),
}),{
  doSubmit: () => submit(BOOKING_FORM_ID)
})(EditBookingModalBase)
