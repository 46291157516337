import { call, put, select, takeLatest, all } from 'redux-saga/effects'
import { replace } from 'connected-react-router'

import * as constants from '../../constants'
import * as entityConstants from '../../entities/service/constants'
import * as serviceApi from 'entities/service/api'

import { fetchDependentData, fetchFilteredRecords } from 'model/sagas'

const getServiceState = (state) => state.views.services.service

function *fetchBaseData() {
 const viewState = yield select(getServiceState)
  yield call( fetchDependentData, viewState, [ 'base' ] )
}

function* fetchServiceRecords( action ) {
  try {
    //if ( debounce )
      //yield delay( debounce )
    const viewState = yield select(getServiceState)
    const [ , ] = yield all([
      call( fetchBaseData ),
    ])
    yield call( fetchFilteredRecords, viewState, serviceApi.getRecords, entityConstants.RECORDS_LOAD )
  } catch (e) {
    console.error( e )
  }
}

function* fetchServiceRecord( action ) {
  try {
    const [ , ] = yield all([
      call( fetchBaseData ),
    ])
    yield call( serviceApi.getRecord, action.payload, action.type )
  } catch (e) {
    console.error( e )
  }
}

function* updateServiceRecord( action ) {
  try {
    yield call( serviceApi.updateRecord, action.payload )
  } catch (e) {
    console.error( e )
  }
}

function* deleteServiceRecord( action ) {
  try {
    yield call( serviceApi.deleteRecord, action.payload )
  } catch (e) {
    console.error( e )
  }
}

function *addService( action ) {
  const record = action.payload;
  yield put({type: entityConstants.EDIT_MODAL_OPEN, payload: record});
}

function *editService( action ) {
  const record = action.payload;
  yield put({type: entityConstants.RECORD_LOAD, payload: record.service_id});
  yield put({type: entityConstants.EDIT_MODAL_OPEN, payload: record});
}

function *serviceRecordUpdated( action ) {
  yield put({type: entityConstants.EDIT_MODAL_CLOSE});
  //yield put({type: entityConstants.EDIT_ACTIONS_MODAL_CLOSE});
  yield call( fetchServiceRecords );
}

function *cancelService( action ) {
  yield( put( replace( "/services" ) ) )
}

function *deleteService( action ) {
  const record = action.payload;
  yield put({type: entityConstants.DELETE_MODAL_OPEN, payload: record});
}

function *serviceRecordDeleted( action ) {
  yield put({type: entityConstants.DELETE_MODAL_CLOSE});
  yield call( fetchServiceRecords );
}

export default function* viewSaga() {
  yield takeLatest( entityConstants.RECORDS_LOAD, fetchServiceRecords );
  yield takeLatest( entityConstants.RECORDS_PAGE, fetchServiceRecords );
  yield takeLatest( entityConstants.RECORDS_SORT, fetchServiceRecords );
  yield takeLatest( entityConstants.RECORDS_SEARCH, fetchServiceRecords );
  yield takeLatest( entityConstants.RECORDS_DATE, fetchServiceRecords );
  yield takeLatest( entityConstants.RECORD_LOAD, fetchServiceRecord );
  yield takeLatest( entityConstants.RECORD_UPDATE, updateServiceRecord );
  yield takeLatest( entityConstants.RECORD_UPDATE+constants.SUCCESS_SUFFIX, serviceRecordUpdated );
  yield takeLatest( entityConstants.RECORD_DELETE, deleteServiceRecord );
  yield takeLatest( entityConstants.RECORD_DELETE+constants.SUCCESS_SUFFIX, serviceRecordDeleted );
  yield takeLatest( entityConstants.ADD, addService );
  yield takeLatest( entityConstants.EDIT, editService );
  yield takeLatest( entityConstants.CANCEL, cancelService );
  yield takeLatest( entityConstants.DELETE, deleteService );
}
