import { combineReducers } from 'redux'

// Reducers
import bookings from './bookings/reducers'
import contacts from './contacts/reducers'
import services from './services/reducers'
import reports from './reports/reducers'

export default combineReducers({
  bookings,
  contacts,
  services,
  reports,
})
