import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { object, string } from 'yup';
import { reduxForm, isValid, submit } from 'redux-form'

import { EditModal } from '../../components/modals/'
import { FormInput, FormTextArea, validateSchema } from '../../components/forms/redux-form/'

const FORM_ID = 'contact';

const schema = object().shape({
  contact_title:       string()/*.min(2)*/.label("Title"),
  contact_first_name:  string()/*.required()*/.label("First Name"),
  contact_last_name:   string()/*.required()*/.label("Last Name"),
  contact_email:       string().email()/*.required()*/.label("Email"),
  contact_phone:       string().nullable().label("Phone"),
  contact_mobile:      string().nullable().label("Mobile"),
  home_address:       object()/*.required()*/.shape({
    address_city:       string()/*.required()*/.label("City"),
    address_country:    string()/*.required()*/.label("Country"),
  }),
});

const ContactFormBase = props => {
  const { handleSubmit, onSubmit } = props
  console.log( "PFB", props );
  return (
    <Form success={props.valid} warning={props.warning} error={props.invalid} onSubmit={()=>handleSubmit(onSubmit)}>
      <Form.Group>
        <FormInput name="contact_title" label="Title" placeholder="Title" width={4}/>
        <FormInput name="contact_first_name" label="First Name" placeholder="First Name" width={6}/>
        <FormInput name="contact_last_name" label="Last Name" placeholder="Last Name" width={6}/>
      </Form.Group>
      <Form.Group>
        <FormInput name="contact_known_as" label="Known As" placeholder="Known As" width={4}/>
        <FormInput name="contact_organisation" label="Org." placeholder="Organisation" width={6}/>
        <FormInput name="contact_email" label="Email" placeholder="Email" width={6}/>
      </Form.Group>
      <Form.Group widths="equal">
        <FormInput name="contact_phone" label="Phone" placeholder="Phone"/>
        <FormInput name="contact_mobile" label="Mobile" placeholder="Mobile"/>
      </Form.Group>
      <Form.Group>
        <FormInput name="home_address.address_line1" label="Address 1" placeholder="Address 1" width={6}/>
        <FormInput name="home_address.address_line2" label="Address 2" placeholder="Address 2" width={5}/>
        <FormInput name="home_address.address_line3" label="Address 3" placeholder="Address 3" width={5}/>
      </Form.Group>
      <Form.Group>
        <FormInput name="home_address.address_city" label="City" placeholder="City" width={6}/>
        <FormInput name="home_address.address_postcode" label="Postcode" placeholder="Postcode" width={4}/>
        <FormInput name="home_address.address_country" label="Country" placeholder="Country" width={6}/>
      </Form.Group>
      <FormTextArea name="contact_notes" label="Notes" placeholder="Notes" rows={4}/>
    </Form>
  )
}

const ContactForm = reduxForm({
  form: FORM_ID,
  validate: validateSchema(schema)
})(ContactFormBase)

const EditContactModalBase = props => {
  const editing = props.record && props.record.contact_id;
  const title = (editing?"Edit":"Create")+" Client"+(editing?(" "+props.record.contact_id):"");
  return(
    <EditModal show={props.show} toggle={props.toggle} title={title} canSubmit={()=>props.valid} onSubmit={props.doSubmit}>
      <ContactForm initialValues={props.record} onSubmit={props.onChange}/>
    </EditModal>
  )
}

const formActions = dispatch => {
  return {
    doSubmit: () => {
      dispatch(submit(FORM_ID));
    },
  }
}

export default connect(store=>({
  valid: isValid(FORM_ID)(store),
}),formActions)(EditContactModalBase)
