import { call, put, select, all } from 'redux-saga/effects'

import api from '../api/'
import { logError } from 'components/logging/sentry'

import actions from './actions'

const modelKeys = {
  base: {
    apiCall: 'getBase',
    spread: true,
    checkKey: 'services',
  },
  contactAttrs: {
    apiCall: 'getContactAttrs',
    spread: true,
    checkKey: 'itemTypes',
  },
  bookingAttrs: {
    apiCall: 'getBookingAttrs',
    spread: true,
    checkKey: 'invoiceTypes',
  },
}

const getModelData = (state) => state.model

export function* fetchModelData( keys ) {
  try {
    const modelData = yield select(getModelData)
    let requestKeys = [];
    let apiRequests = [];
    keys.forEach( key => {
      if ( !modelKeys[key] )
      {
        console.warn( "No api request for model key "+key );
        return
      }
      const keyData = modelKeys[key];
      if ( keyData.spread )
      {
        if ( modelData[keyData.checkKey] === undefined )
        {
          requestKeys.push( key )
          apiRequests.push( call([ api, modelKeys[key].apiCall ]) )
        }
        else
          console.log( "ModelData "+key+" already loaded" );
      }
      else
      {
        if ( modelData[key] === undefined )
        {
          requestKeys.push( key )
          apiRequests.push( call([ api, modelKeys[key].apiCall ]) )
        }
        else
          console.log( "ModelData "+key+" already loaded" );
      }
    })
    const requests = yield all( apiRequests );
    let newModelData = {};
    requestKeys.forEach( ( key, index ) => {
      const keyData = modelKeys[key];
      if ( keyData )
        if ( keyData.spread  )
          Object.keys(requests[index].data).forEach( subKey => 
            newModelData[subKey] = requests[index].data[subKey]
          )
        else
          newModelData[key] = requests[index].data
    })
    console.log( "newModelData", newModelData )
    if ( Object.keys(newModelData).length > 0 )
      yield put( actions.modelDataUpdate( newModelData ) )
  } catch (e) {
    console.error( e )
    logError( e )
  }
}

export function* fetchDependentData( state, modelData ) {
  yield call( fetchModelData, modelData )
}

export function* fetchRecords( apiCall ) {
  try {
    return yield call( apiCall )
  } catch (e) {
    console.error( e )
    logError( e )
  }
}

export function* fetchFilteredRecords( state, apiCall, ...args ) {
  let parms = [];
  if ( !state.filter.local )
  {
    if ( state.filter.paging )
    {
      if ( state.filter.paging.current )
        parms.push( 'page.num='+state.filter.paging.current );
      if ( state.filter.paging.size )
        parms.push( 'page.size='+state.filter.paging.size );
    }
    if ( state.filter.sort.col )
      parms.push( 'sort.'+state.filter.sort.col+'='+(state.filter.sort.asc?'a':'d') );
    Object.keys(state.filter.search).forEach( field => {
      if (
        state.filter.search[field] !== null
        && state.filter.search[field] !== undefined
        && state.filter.search[field] !== ""
        && state.filter.search[field].length > 0
        )
        parms.push( 'search.'+field+'='+encodeURIComponent(state.filter.search[field]) );
    })
    Object.keys(state.filter.filter).forEach( field => {
      if (
        state.filter.filter[field] !== null
        && state.filter.filter[field] !== undefined
        && state.filter.filter[field] !== ""
        && state.filter.filter[field].length > 0
        )
        parms.push( 'filter.'+field+'='+encodeURIComponent(state.filter.filter[field]) );
    })
    if ( state.filter.date.start )
      parms.push( 'date.start='+state.filter.date.start );
    if ( state.filter.date.end )
      parms.push( 'date.end='+state.filter.date.end );
    if ( state.filter.date.group )
      parms.push( 'date.group='+state.filter.date.group );
    if ( state.filter.selectors )
      Object.keys(state.filter.selectors).forEach( selector => parms.push( 'selector.'+selector ) )
  }
  try {
    console.log( "Parms", parms, args );
    return yield call( apiCall, parms, ...args )
  } catch (e) {
    console.error( e )
    logError( e )
  }
}
