import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Menu, Dropdown } from 'semantic-ui-react'
import { connect } from 'react-redux'

import config from '../config/';

import accessActions from 'access/actions'
import dialogActions from 'dialogs/actions'

const Nav = props => {

  const { configModalOpen } = { ...props }

  return(
    <Menu fluid fixed="top" inverted size="large">
      <Menu.Item as={Link} to={"/"} header>{config.SITE_TITLE} (v{config.VERSION+(process.env.NODE_ENV==='production'?'':'-dev')})</Menu.Item>
      <Menu.Item as={NavLink} to={"/bookings"}>Bookings</Menu.Item>
      <Menu.Item as={NavLink} to={"/contacts"}>Contacts</Menu.Item>
      <Menu.Item as={NavLink} to={"/services"}>Services</Menu.Item>
      <Menu.Item as={NavLink} to={"/reports/sales/total"}>Sales Report</Menu.Item>
      <Dropdown item simple icon="bars" style={{marginLeft:'auto'}}>
        <Dropdown.Menu direction="left">
          <Dropdown.Item icon="configure" content="Config" onClick={configModalOpen}/>
          <Dropdown.Item icon="sign-out" content="Logout" onClick={()=>props.accessLogout()}/>
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  )
}

export default connect(
  null,
  {
    ...accessActions,
    ...dialogActions,
  }
)(Nav)
