import { call, put, select, takeLatest, all } from 'redux-saga/effects'
import { push, replace } from 'connected-react-router'

import * as constants from '../../constants'
import * as entityConstants from '../../entities/booking/constants'
import * as bookingApi from 'entities/booking/api'

import { fetchDependentData, fetchFilteredRecords } from 'model/sagas'

const getBookingState = (state) => state.views.bookings.booking

function *fetchBaseData() {
 const viewState = yield select(getBookingState)
  yield call( fetchDependentData, viewState, [ 'base' ] )
}

function* fetchBookingTotals() {
  const viewState = yield select(getBookingState)
  yield call( fetchFilteredRecords, viewState, bookingApi.getTotals, entityConstants.TOTALS_LOAD )
}

function* fetchBookingRecords( action ) {
  try {
    //if ( debounce )
      //yield delay( debounce )
    const viewState = yield select(getBookingState)
    const [ , ] = yield all([
      call( fetchBaseData ),
      call( fetchBookingTotals ),
    ])
    yield call( fetchFilteredRecords, viewState, bookingApi.getRecords, entityConstants.RECORDS_LOAD )
  } catch (e) {
    console.error( e )
  }
}

function* fetchBookingRecord( action ) {
  try {
    const [ , ] = yield all([
      call( fetchBaseData ),
    ])
    yield call( bookingApi.getRecord, action.payload, action.type )
  } catch (e) {
    console.error( e )
  }
}

function* updateBookingRecord( action ) {
  try {
    yield call( bookingApi.updateRecord, action.payload )
  } catch (e) {
    console.error( e )
  }
}

function* deleteBookingRecord( action ) {
  try {
    yield call( bookingApi.deleteRecord, action.payload )
  } catch (e) {
    console.error( e )
  }
}

function *addBooking( action ) {
  const record = action.payload;
  yield put({type: entityConstants.EDIT_MODAL_OPEN, payload: record});
}

function *editBooking( action ) {
  const record = action.payload;
  yield put({type: entityConstants.RECORD_LOAD, payload: record.booking_id });
  yield put({type: entityConstants.EDIT_MODAL_OPEN, payload: record});
}

function *bookingRecordUpdated( action ) {
  yield put({type: entityConstants.EDIT_MODAL_CLOSE});
  yield call( fetchBookingRecords );
}

function *cancelBooking( action ) {
  yield( put( replace( "/bookings" ) ) )
}

function *deleteBooking( action ) {
  const record = action.payload;
  yield put({type: entityConstants.DELETE_MODAL_OPEN, payload: record});
}

function *bookingRecordDeleted( action ) {
  yield put({type: entityConstants.DELETE_MODAL_CLOSE});
  yield call( fetchBookingRecords );
}

function *printBooking( action ) {
  const record = action.payload;
  yield( put( push( "/booking/"+record.booking_id+"/print" ) ) )
}

export default function* viewSaga() {
  yield takeLatest( entityConstants.RECORDS_LOAD, fetchBookingRecords );
  yield takeLatest( entityConstants.RECORDS_PAGE, fetchBookingRecords );
  yield takeLatest( entityConstants.RECORDS_SORT, fetchBookingRecords );
  yield takeLatest( entityConstants.RECORDS_SEARCH, fetchBookingRecords );
  yield takeLatest( entityConstants.RECORDS_DATE, fetchBookingRecords );
  yield takeLatest( entityConstants.RECORD_LOAD, fetchBookingRecord );
  yield takeLatest( entityConstants.RECORD_UPDATE, updateBookingRecord );
  yield takeLatest( entityConstants.RECORD_UPDATE+constants.SUCCESS_SUFFIX, bookingRecordUpdated );
  yield takeLatest( entityConstants.RECORD_DELETE, deleteBookingRecord );
  yield takeLatest( entityConstants.RECORD_DELETE+constants.SUCCESS_SUFFIX, bookingRecordDeleted );
  yield takeLatest( entityConstants.ADD, addBooking );
  yield takeLatest( entityConstants.EDIT, editBooking );
  yield takeLatest( entityConstants.CANCEL, cancelBooking );
  yield takeLatest( entityConstants.DELETE, deleteBooking );
  yield takeLatest( entityConstants.PRINT, printBooking );
}
