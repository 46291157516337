import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';

import { SaveButton, CancelButton, DeleteButton } from '../buttons/'

export class AlertModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    buttonSize: PropTypes.string,
  }

  toggle = event => {
    this.props.toggle();
    if ( event )
      event.preventDefault();
  }

  render() {
    const { show, toggle, title, message, buttonSize, ...modalProps } = this.props;
    return (
      <Modal className="alert-modal" open={show} onClose={toggle} closeIcon {...modalProps}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          <p className="message">{message}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button type="submit" color="primary" size={buttonSize} onClick={toggle}>Ok</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export class ConfirmModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    buttonSize: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
  }

  toggle = event => {
    this.props.toggle();
    if ( event )
      event.preventDefault();
  }

  render() {
    const { show, toggle, title, message, buttonSize, onSubmit, ...modalProps } = this.props;
    return (
      <Modal className="confirm-modal" open={show} onClose={toggle} closeIcon {...modalProps}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          <p className="message">{message}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button type="submit" primary size={buttonSize} onClick={onSubmit}>Yes</Button>
          <Button type="button" secondary size={buttonSize} onClick={toggle}>No</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export class EditModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    buttonSize: PropTypes.string,
    submitText: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    canSubmit: PropTypes.func,
  }

  toggle = event => {
    this.props.toggle();
    if ( event )
      event.preventDefault();
  }

  render() {
    const { show, toggle, title, buttonSize, submitText, onSubmit, canSubmit, onDelete, extraControls, children, ...modalProps } = this.props;
    return (
      <Modal className="edit-modal" open={show} closeOnEscape={false} closeOnDimmerClick={false} onClose={toggle} closeIcon {...modalProps}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          {children}
        </Modal.Content>
        <Modal.Actions>
          {onDelete &&
          <DeleteButton floated="left" size={buttonSize} onClick={onDelete} content="Delete"/>
          }
          {extraControls}
          <SaveButton type="submit" size={buttonSize} onClick={onSubmit} disabled={canSubmit&&!canSubmit()} content={submitText||"Save"}/>
          <CancelButton size={buttonSize} onClick={toggle} content="Cancel"/>
        </Modal.Actions>
      </Modal>
    );
  }
}
