import React, { Suspense, lazy } from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { Dimmer, Loader } from 'semantic-ui-react'

import AppContext from './context'
import { Error404 } from 'views/error'

const Bookings = lazy(() => import('views/bookings/'))
const BookingForm = lazy(() => import('views/bookings/form'))
const BookingPrint = lazy(() => import('views/bookings/print'))
const Contacts = lazy(() => import('views/contacts/'))
const Services = lazy(() => import('views/services/'))
const SalesByMonthReport = lazy(() => import('views/reports/sales/total'))

const Routes = props => {
  return(
    <Suspense fallback={<Dimmer active={true} inverted page><Loader/></Dimmer>}>
      <Switch>
        <Route exact path="/">
          <Redirect to={"/bookings"}/>
        </Route>
        <Route exact path="/bookings" component={Bookings}/>
        <Route exact path="/booking/:bookingId/print" component={BookingPrint}/>
        <Route exact path="/booking/:bookingId" component={BookingForm}/>
        <Route exact path="/contacts" component={Contacts}/>
        <Route exact path="/services" component={Services}/>
        <Route exact path="/reports/sales/total" component={SalesByMonthReport}/>
        <Route component={Error404}/>
      </Switch>
    </Suspense>
  )
}

const ConnectedRoutes = withRouter(Routes)

export default () => {
  return(
    <AppContext.Consumer>
    {() => (
      <ConnectedRoutes/>
    )}
    </AppContext.Consumer>
  )
}
