import { createSelector } from 'reselect'

export const getDataModel = state => state.model

//export const getUser = createSelector(
  //[ getDataModel ],
  //dataModel => dataModel.user
//)

export const getServices = createSelector(
  [ getDataModel ],
  dataModel => {
    return dataModel.services
  }
)
export const getServicesByTag = createSelector(
  [ getServices ],
  services => {
    return services ? services.reduce( ( obj, service ) => { obj[service.service_tag] = service.service_name; return obj }, {} ) : {}
  }
)

export const getInvoiceTerms = createSelector(
  [ getDataModel ],
  dataModel => {
    return dataModel.invoiceTerms
  }
)
