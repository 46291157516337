import { fork, all } from 'redux-saga/effects'

import appSaga from 'app/sagas'
import accessSaga from 'access/sagas'
import bookingsSaga from 'views/bookings/sagas'
import contactsSaga from 'views/contacts/sagas'
import servicesSaga from 'views/services/sagas'
import reportsSaga from 'views/reports/sagas'

export default function* rootSaga() {
  yield all([
    fork(appSaga),
    fork(accessSaga),
    fork(bookingsSaga),
    fork(contactsSaga),
    fork(servicesSaga),
    fork(reportsSaga),
  ])
}
